import data from "../../data/index.json";
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import ProjectDetail from './ProjectDetail';

export default function MyPortfolio() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  const handleClick = (item, index) => {
    if (index === 0 || index === 3) {
      window.open(item.video, '_blank', 'noopener,noreferrer');
    } else {
      openModal(item);
    }
  };

  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container-box">
        <div className="portfolio--container">
          <p className="sub--title">Recent Projects</p>
          <h2 className="skills--section--heading">My Portfolio</h2>
        </div>
        <div>
          <a href="https://github.com/memokr" className="btn btn-github">
            Visit My GitHub
          </a>
        </div>
      </div>
      <div className="portfolio--section--container">
        {data?.portfolio?.map((item, index) => (
          <div
            key={index}
            className="portfolio--section--card"
            onClick={() => handleClick(item, index)}
          >
            <div className="portfolio--section--img">
              <img src={item.src} alt="Placeholder" />
            </div>
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">{item.title}</h3>
                <br />
                <p className="text-sm">{item.description}</p>
              </div>
              <p className="text-sm portfolio--link">
                {index === 0 || index === 3 ? (
                  <a
                    href={item.video}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="portfolio--link--none"
                    style={{ color: "black" }}
                    onClick={(e) => e.stopPropagation()} // Prevent modal from opening
                  >
                    {item.link}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <path
                        d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                        stroke="black"
                        strokeWidth="2.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                ) : (
                  <>
                    {item.link}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <path
                        d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                        stroke="black"
                        strokeWidth="2.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
      <ProjectDetail
        project={selectedProject}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </section>
  );
}
