import React from 'react';

const ProjectDetail = ({ project, isOpen, onClose }) => {
  if (!isOpen || !project) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <button className="modal-close-button" onClick={onClose}>
            &times; {/* Black 'x' character */}
          </button>
          <h2>{project.title}</h2>
          <br></br>
          <p>{project.description}</p>
          <div className="video-container">
            <iframe
              width="560"
              height="500"
              src={project.video}
              title={project.title}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
