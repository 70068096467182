export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="portfolio--container">
      <p className="section--title">Background and Passion</p>
      <h1 className="skills-section--heading">About Me</h1>
        <div className="about--section--content--box about--section--box">
          <div className="about--section--content">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p className="about--section-description">
              Hello, I'm Guillermo Kramsky, and I'm genuinely passionate about coding. The blend of creativity, logic, and the power to create truly fascinates me. I consider myself a person who finds joy in discipline and consistency.
            </p>
            <br></br>
            <p className="about--section-description">
              I've recently finished university and am currently enrolled in the Apple Developer Academy until June of 2024. This opportunity ideally suited my passion for coding and provides me with invaluable skills on iOS development.
            </p>
            <br></br>
            <p className="about--section-description">
              Beyond coding, I maintain a healthy balance in life with a dedicated fitness routine at the gym – a principal and recurring hobby. I also relish my free time, whether it's socializing with friends, taking walks in the park, exploring new destinations through travel, or simply embracing the opportunities that this limited journey called life offers.
            </p>
          </div>
          <div className="about--section--image">
            <img src="./img/avatar-image-3.jpg" alt="Image description" />
          </div>
        </div>
      </div>
    </section>
  );
}
