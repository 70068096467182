export default function ContactMe() {
  return (
    <section id="Contact" class="contact--section">
      <div>
      <h2 className="skills--section--heading">Contact Me</h2>
   
        <br></br>
        <br></br>
        <br></br>

        <div class="contact-buttons">
          <a href="./pdf/GuillermoKramsky-CV.pdf" download="GuillermoKramsky-CV.pdf" class="btn btn-primary">
            Download CV
          </a>
          <a href="mailto:memo.kramsky@gmail.com" class="btn btn-primary">
            Write Email
          </a>
        </div>
      </div>
    </section>


  );
}
